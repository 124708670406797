import React, { useState } from 'react';
import { motion } from "framer-motion";
import cx from 'classnames';
import "./SplitScreen.scss";
import getMe from '../../utils/getMe';
import { getUserParams } from '../../utils/getUserParams';
import { fireGtmEvent } from '../../utils/analytics/gtmActions';
import LogoutIcon from "../../assets/teacherApp/classroom/log-out-outline.svg";
import personCircleIcon from './personCircleIcon.svg';
import { gtmEvents } from '../../utils/analytics/gtmEvents';
import UpdateProfileModal from './components/UpdateProfileModal';

const Dropdown = ({ isVisible, setModalVisibility, isProfileModalVisible, logoutModalOpen, containerClassName, ...props }) => {
    const modalRef = React.createRef()

    return (
        <>
      <motion.div
        className={cx("profileDropdown", containerClassName)}
        animate={{
          opacity: isVisible ? 1 : 0,
          y: isVisible ? 0 : 10,
          pointerEvents: isVisible ? "all" : "none"
        }}
        transition={{
          delay: isVisible ? 0 : 0.3
        }}
        initial={{
          opacity: isVisible ? 1 : 0,
          y: isVisible ? 0 : 10,
          pointerEvents: isVisible ? "all" : "none"
        }}
      >
        <div className='profile' onClick={() => setModalVisibility(true)}>
        <img src={personCircleIcon} alt={"Logout icon"} /> My Profile
        </div>
        <span
            className="logout-btn "
            onClick={() => {
                logoutModalOpen()
                const me = getMe()
                const userParams =  getUserParams()
                fireGtmEvent(gtmEvents.logoutButtonClicked,{userParams})
            }}
            >
            <img src={LogoutIcon} alt={"Logout icon"} /> Logout
        </span> 

      </motion.div>
        {isProfileModalVisible && (
            <UpdateProfileModal modalRef={modalRef} setModalVisibility={setModalVisibility} {...props}/>
        )}
      </>
    )
}

export default Dropdown