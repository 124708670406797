import cuid from 'cuid';
import { Base64 } from "js-base64";

const generateSystemId = () => {
    let uidObj = {}
    if (typeof window === 'undefined') return
    if (typeof window !== "undefined") {
        const navigatorInfo = window.navigator;
        const screen_info = window.screen;
        uidObj.userAgent = navigatorInfo.userAgent;
        uidObj.screenHeight = screen_info.height;
        uidObj.screenWidth = screen_info.width;
        uidObj.pixelDepth = screen_info.pixelDepth;
        uidObj.id = cuid();
    }
    uidObj = JSON.stringify(uidObj)
    const encodedUid = Base64.encode(uidObj);
    localStorage.setItem("systemId", encodedUid)
    return encodedUid
}

const getSystemId = () => {
    if (localStorage.getItem('systemId')) {
        return localStorage.getItem("systemId")
    }
    return generateSystemId()
}

export default getSystemId;
