import React, { lazy } from 'react'
import withSuspense from './components/Loading/withSuspense'
import { get } from 'lodash'
import Switch, { Route } from './components/RouterWithNav'
import PrivateRoute from './PrivateRoute'
import { routeType } from './constants';
import { getRoutesList } from './routesConfig';
import { isSubDomainActive } from './utils/extractSubdomain'

const GlobalOverlay = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "overlay" */`./components/GlobalOverlay`)))
  : () => <div />

// ⚠️ Note: All new routes should also be added to ./serverRoutes.js
//  else server side rendering will break
const routes = (props) => {
  const { PRIVATE } = routeType
  return (
    <>

      {
        (props.location && props.location.pathname && !props.location.pathname.startsWith('/sessions/'))
          ? (
            <GlobalOverlay {...props} />
          ) :
          (
            <div />
          )
      }
      <Switch>
        {[...getRoutesList(props).map((route, key) => {
          const { config: routeConfig, path, name = '', Component } = route
          if (isSubDomainActive && routeConfig && routeConfig.allowOnSubDomain === 'block') {
            return null;
          }
          if (Component) {
            if (get(routeConfig, 'routeType') === PRIVATE) {
              return <PrivateRoute exact path={path} name={name} component={Component} {...routeConfig} />
            }
            return <Route exact path={path} name={name} component={Component} {...routeConfig} />
          }
          return null
        }), <Route path='/not-found' noNav component={() => <div>Not Found</div>} />, <Route path='/' component={() => <div>Not Found</div>} />, <Route path='*' component={() => <div>Not Found</div>} />]}
        {/* <Route exact path='/' component={getLandingPage()} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/spy-squad-camp' component={RadioStreetLandingPageOld} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/crack-the-code' component={RadioStreetLandingPage} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/spy-squad-camp-community' component={RadioStreetLandingPageOld} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/crack-the-code-community' component={RadioStreetLandingPage} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/spy-squad-camp-school' component={RadioStreetLandingPageOld} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/trailer' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/intro-to-programming' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/if-else-statements' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/tekie-tour' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/tekie-story-naman-sonpar' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/snippets/:ytId' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/switch-account' component={SwitchAccount} noNav />
        <Route exact path='/privacy' noNav component={Privacy} />
        <Route exact path='/terms' noNav component={Terms} />
        <Route exact path='/refund' noNav component={Refund} />
        <Route exact path='/signup-school' noNav component={SignupSchool} />
        <Route exact path='/login' noNav component={SignupLogin} />
        <Route exact path='/course' component={Sessions} hideNavLoggedOut />
        <Route exact path='/signup' noNav component={Signup} />
        <Route exact path='/registers' noNav component={LandingPage} />
        <Route exact path='/signup/spy-squad-camp' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/crack-the-code' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/crack-the-code-community' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/spy-squad-camp-community' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/crack-the-code-school' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/spy-squad-camp-school' noNav component={SignupRadioStreet} />
        <Route exact path='/signin' noNav component={Signup} />
        <Route exact path='/school/liveclass/login' noNav component={SchoolLiveClassLogin} />
         */}

        {/* <Route exact path='/pre-checkout' noNav component={PreCheckout} />
        <PrivateRoute privateRoute={false} exact path='/student-community' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' topRootNav />
        <PrivateRoute privateRoute={false} exact path='/student-community/new' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' topRootNav />
        <PrivateRoute privateRoute={false} exact path='/student-community/best' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' topRootNav />
        <PrivateRoute privateRoute={false} exact path='/student-community/trending' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' topRootNav />
        <PrivateRoute privateRoute={false} exact path='/student-community/:id' component={IndividualCodeShowcase} className='ind-code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut topRootNav /> */}
        {/* <Route exact path='/course-completion/:code' component={CertificateShowcase} className='cert-showcase' fromSSR={props.fromSSR} noNav />
        <Route exact path='/' component={SubDomainLandingPage} className='subDomain-landing-page' fromSSR={props.fromSSR} noNav name='Sub Domain Landing Page' />
        <Route exact path='/event-certificate/:code' component={EventCertificateShowcase} className='cert-showcase' noNav />
        <Route exact path='/iqa-report/:code' component={IqaReportShowcase} className='cert-showcase' noNav topRootNav />
        <PrivateRoute exact path='/selected-code/stats' component={CodeShowcaseStats} name='Published Code' topRootNav />
        <PrivateRoute exact path='/sessions' component={Sessions} appSideNav navItem sessionsOnly name='sessions' outSideNav showInHamMenu withUpdatedSideNav  />
        <PrivateRoute exact path='/sessions/book' component={Sessions} appSideNav sessionsOnly name='sessions' />
        <PrivateRoute exact path='/learn' component={HomePage} navItem name='learn' hideInSession />
        <PrivateRoute privateRoute={false} exact path='/code-playground' component={() => <Editor fullEditor></Editor>} navItem name='code playground' outSideNav withUpdatedSideNav />
        <PrivateRoute exact path='/code-playground/:id' component={Editor} name='code playground' parent='code playground' outSideNav withUpdatedSideNav />
        <PrivateRoute exact path='/homework' component={Homework} sessionsOnly name='homework' background='#FAFAFA' outSideNav withUpdatedSideNav />
        <PrivateRoute exact path='/video/:topicId' component={Video} sideNavItem parent='learn' />
        <PrivateRoute exact path='/video/:topicId/:learningObjectiveId' component={Video} sideNavItem parent='learn' />
        <PrivateRoute exact path='/sessions/video/:topicId/discussion' component={VideoDiscussion}  sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/:topicId/discussion' component={Discussion} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/chat/:topicId/:loId' component={ChatPage} sideNavItem parent='learn' keyPath="loId" />
        <PrivateRoute exact path='/practice/:topicId/:loId' component={Practice} sideNavItem parent='learn' />
        <PrivateRoute exact path='/pq-report' component={PQReport} sideNavItem parent='learn' />
        <PrivateRoute exact path='/practice-report/:topicId/:loId' component={PQReport} sideNavItem parent='learn' />
        <PrivateRoute exact path='/quiz/:topicId' component={Quiz} sideNavItem parent='learn' />
        <PrivateRoute exact path='/quiz-report-first/:topicId' component={QuizReport} param sideNavItem parent='learn' />
        <PrivateRoute exact path='/quiz-report-latest/:topicId' component={QuizReport} sideNavItem parent='learn' />
        <PrivateRoute exact path='/see-answers-first/:topicId' component={SeeAnswers} sideNavItem parent='learn' />
        <PrivateRoute exact path='/see-answers-latest/:topicId' component={SeeAnswers} sideNavItem parent='learn' /> */}
        {/* Session Routes */}
        {/* <PrivateRoute exact path='/sessions/video/:topicId' component={Video} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/video/:courseId/:topicId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav noMobileHeader />
        <PrivateRoute
          exact
          path='/quiz-report-first/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='homework'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/quiz-report-latest/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='homework'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/quiz-report-first/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/quiz-report-latest/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/chat/:topicId/:loId'
          parent='sessions'
          keyPath="loId"
          component={ChatPage}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
        />
        <PrivateRoute
          exact
          path='/sessions/chat/:courseId/:topicId/:loId'
          parent='sessions'
          keyPath="loId"
          component={ChatPage}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/revisit/sessions/chat/:courseId/:topicId/:loId'
          parent='sessions'
          keyPath="loId"
          component={ChatPage}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          revisitRoute
          noMobileHeader
        />
        <PrivateRoute exact path='/sessions/practice-report/:courseId/:topicId/:loId' component={PQReport} withUpdatedSideNav noMobileHeader />
        <PrivateRoute
          exact
          path='/sessions/practice-report/:topicId/:loId'
          component={PQReport}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute exact path='/sessions/practice/:topicId/:loId' component={Practice} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/quiz-report-latest/:topicId' component={QuizReport} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/see-answers-latest/:topicId' component={SeeAnswers} sideNavItem parent='sessions' />

        <PrivateRoute exact path='/sessions/comic-strip/:courseId/:topicId/:loId' component={ComicStrip} className='Comic Strips' withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/comic-strip/:courseId/:topicId/:loId' revisitRoute component={ComicStrip} className='Comic Strips' withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/sessions/project/:courseId/:topicId/:projectId' component={UpdatedProject} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/sessions/practice/:courseId/:topicId/:projectId' component={UpdatedPractice} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/homework/:courseId/:topicId/:projectId/practice' component={UpdatedPractice} withUpdatedSideNav parent='homework' />
        <PrivateRoute exact path='/sessions/practice-quiz/:courseId/:topicId/:loId' component={UpdatedPracticeQuiz} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/project/:courseId/:topicId/:projectId' revisitRoute component={UpdatedProject} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/practice/:courseId/:topicId/:projectId' revisitRoute component={UpdatedPractice} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/practice-quiz/:courseId/:topicId/:loId' revisitRoute component={UpdatedPracticeQuiz} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/practice-report/:courseId/:topicId/:loId' revisitRoute component={PQReport} withUpdatedSideNav noMobileHeader />

        <PrivateRoute exact path='/homework-assignment/:topicId/' component={CodingAssignment} sideNavItem parent='learn' />
        <PrivateRoute exact path='/homework/:topicId/codingAssignment' component={CodingAssignment} sideNavItem parent='homework' />
        <PrivateRoute exact path='/homework/:courseId/:topicId/codingAssignment' component={CodingAssignmentNew} withUpdatedSideNav parent='homework' />
        <PrivateRoute exact path='/homework/:topicId/quiz' component={Quiz} sideNavItem parent='homework' />
        <PrivateRoute
          exact
          path='/homework/:courseId/:topicId/quiz'
          component={getComponentBasedOnCourse(Quiz, UpdatedQuiz)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='homework'
        />
        <PrivateRoute
          exact
          path='/sessions/:courseId/:topicId/quiz'
          component={UpdatedQuiz}
          withUpdatedSideNav
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/:courseId/:topicId/codingAssignment'
          component={CodingAssignmentNew}
          withUpdatedSideNav
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/:courseId/:topicId/:projectId/practice'
          component={UpdatedPractice}
          withUpdatedSideNav
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/coding/:topicId'
          component={CodingAssignment}
          parent='sessions'
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
        />
        <PrivateRoute
          exact
          path='/revisit/sessions/coding/:topicId'
          component={CodingAssignment}
          parent='sessions'
          revisitRoute
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
        />
        <PrivateRoute
          exact
          path='/sessions/coding/:courseId/:topicId'
          component={CodingAssignmentNew}
          parent='sessions'
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/revisit/sessions/coding/:courseId/:topicId'
          component={CodingAssignmentNew}
          parent='sessions'
          revisitRoute
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          noMobileHeader
        />
        <PrivateRoute exact path='/sessions/learning-slides/:courseId/:topicId/:loId' component={UpdatedLearningSlides} name='Learning Slides' withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/learning-slides/:courseId/:topicId/:loId' component={UpdatedLearningSlides} name='Learning Slides' withUpdatedSideNav noMobileHeader revisitRoute />
        <PrivateRoute exact path='/sessions/codingAssignment/:topicId' component={CodingAssignment} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/codingAssignment/:topicId' component={CodingAssignment} sideNavItem parent='learn' />
        <PrivateRoute exact path='/revisit/sessions/video/:topicId' component={Video} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/sessions/video/:courseId/:topicId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav revisitRoute noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/chat/:topicId/:loId' component={Chat} sideNavItem parent='sessions' revisitRoute keyPath="loId" />
        <PrivateRoute exact path='/revisit/sessions/practice/:topicId/:loId' component={Practice} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/sessions/practice-report/:topicId/:loId' component={PQReport} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/homework/:topicId/codingAssignment' component={CodingAssignment} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/homework/:courseId/:topicId/codingAssignment' component={CodingAssignment} sideNavItem parent='sessions' revisitRoute noMobileHeader />
        <PrivateRoute exact path='/revisit/homework/:topicId/quiz' component={Quiz} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/homework/:courseId/:topicId/quiz' component={Quiz} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/settings/account' component={AccountPage} sideNavItem parent='settings' />
        <PrivateRoute exact path='/settings/journeyreport' component={JourneyReport} sideNavItem parent='settings' />
        <PrivateRoute exact path='/settings/achievements/:type' component={Achievements} sideNavItem parent='settings' />
        <PrivateRoute exact path='/settings/support' component={ComingSoon} sideNavItem parent='settings' />
        {referralFeature && <Route exact path='/settings/invite' component={Invite} sideNavItem parent='settings' showInHamMenu name="Gift & Earn" hideNavLoggedOut />}
        {referralFeature && <PrivateRoute exact path='/settings/myReferrals' component={MyReferrals} sideNavItem parent='settings' showInHamMenu name="My Credits" />}
        <PrivateRoute exact path='/sessions/video/:courseId/:topicId/:videoId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav parent='sessions' noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/video/:courseId/:topicId/:videoId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav parent='sessions' revisitRoute noMobileHeader />
        <PrivateRoute exact path='/sessions/video/:topicId/:learningObjectiveId' component={Video} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/checkout' noNav component={Checkout} />
        <PrivateRoute exact path='/codePlayground' component={CodePlayground} name='Saved Codes' topRootNav />
        <Route exact path='/cheatsheet' navItem component={CheatSheet} name='Resources' hideNavLoggedOut />
        <Route exact path='/cheatsheet/:topicId' component={CheatSheet} name='CheatSheet' hideNavLoggedOut />
        <Route exact path='/cheatsheet/:topicId/:cheatsheetId' component={CheatSheet} name='CheatSheet' hideNavLoggedOut />
        <PrivateRoute exact path='/my-code' component={CodePlayground} name='My Code' topRootNav /> */}
        {/* School Dashboard Routes */}
        {/* <PrivateRoute exact path='/dashboard/:schoolSlug/profile' noNav component={SchoolProfile} name='Mentor Profile' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/mentors/:mentorId' noNav component={SchoolMentorProfile} name='Mentor Profile' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/mentors' noNav component={SchoolMentors} name='School Mentor' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/students/:studentId' noNav component={SchoolStudentProfile} name='Student Profile' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/students' noNav component={SchoolStudents} name='School Students' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/grades' noNav component={SchoolGrades} name='School Grades' />
        <PrivateRoute exact path='/dashboard/:schoolSlug' noNav component={SchoolDashboard} name='School Dashboard' />
        <Route exact path='/apps/binary-spy-tool' component={Decrypter} name='Decrypter' noNav />
        <Route exact path='/events' component={EventsLandingPage} name='EventLandingPage' hideNavLoggedOut showInHamMenu />
        <Route exact path='/events/:eventId' component={EventDescPage} name='EventDescPage' hideNavLoggedOut />
        <PrivateRoute exact path='/time-table' activeRoute='time-table' component={TimeTable} managementApp noNav />
        <PrivateRoute exact path='/reports' activeRoute='reports' component={ClassroomHomePage} managementApp noNav />
        <PrivateRoute exact path='/classrooms' activeRoute='classrooms' component={Classrooms} managementApp noNav />
        <PrivateRoute exact path='/reports/classroom/:batchId' activeRoute='reports' component={ClassroomDetails} managementApp noNav />
        <PrivateRoute exact path='/session-embed' activeRoute={getActiveTeacherAppRoute('session-embed')} component={SessionEmbed} managementApp noNav />
        <Route exact path='/redirect/:redirectId' component={RedirectPage} name='EventDescPage' hideNavLoggedOut />
        <PrivateRoute exact path='/homework-review' activeRoute='homework-review' component={HomeworkReview} managementApp noNav />
        <PrivateRoute exact path='/pq-report' activeRoute='pq-report' component={PqReport} managementApp noNav />
        <Route exact path='/teacher-report/:userId/:topicId' component={PqReportTeacherTraining} noNav noOverflow />
        <PrivateRoute exact path='/sessions/homework-review/:courseId/:topicId' activeRoute='homework-review' component={HomeworkReview} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/sessions/pq-report/:courseId/:topicId/:loId' activeRoute='homework-review' component={PqReport} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/pq-report/:courseId/:topicId/:loId' activeRoute='homework-review' component={PqReport} withUpdatedSideNav noMobileHeader revisitRoute />
        <PrivateRoute exact path='/reports/classroom/:sessionId/student-level' activeRoute='reports' component={StudentLevelReport} managementApp noNav />
        <PrivateRoute exact path='/reports/classroom/:sessionId/question-level' activeRoute='reports' component={DetailedReport} managementApp noNav />
        <PrivateRoute exact path='/reports/classroom/:sessionId/student-level/:userId' activeRoute='reports' component={IndividualStudentLevelReport} managementApp noNav />
        <PrivateRoute exact path='/classrooms/:batchId' activeRoute='classrooms' component={ClassroomCourseListing} managementApp noNav /> */}

      </Switch>
    </>
  )
}

export default routes