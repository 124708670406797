import React from "react";
import clx from "classnames";
import PropTypes from "prop-types";

import classes from "./stack.module.scss";

const Stack = ({
	spacing,
	direction,
	className,
	children,
	as,
	justifyContent,
	alignItems,
	style,
	...props
}) => {
	const Component = as || "div";

	return (
		<Component
			className={clx(
				classes.stack,
				classes[direction],
				classes[`s${spacing}`],
				className
			)}
			style={{
				justifyContent,
				alignItems,
				...style,
			}}
			{...props}
		>
			{children}
		</Component>
	);
};

// prop types with jsdoc comments
Stack.propTypes = {
	/**
	 * The content of the component.
	 * @type {React.ReactNode}
	 * @required
	 * @default null
	 * */
	children: PropTypes.node.isRequired,
	/**
	 * The className of the component.
	 * @type {string}
	 * @default null
	 * */
	className: PropTypes.string,	
	/**
	 * The spacing of the component.
	 * @type {number}
	 * @required
	 * @default null
	 * */
	spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
	/**
	 * The direction of the component.
	 * @type {string}
	 * @default "column"
	 * */
	direction: PropTypes.oneOf(["row", "column"]),
	/**
	 * The as of the component.
	 * @type {string}
	 * @default null
	 * */
	as: PropTypes.string,
	/**
	 * The justifyContent of the component.
	 * @type {string}
	 * @default null
	 * */
	justifyContent: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around", "space-evenly"]),
	/**
	 * The alignItems of the component.
	 * @type {string}
	 * @default null
	 * */
	alignItems: PropTypes.oneOf(["flex-start", "flex-end", "center", "stretch", "baseline"]),
	/**
	 * The style of the component.
	 * @type {object}
	 * @default null
	 * */
	style: PropTypes.object,
};

export default Stack;
