import axios from 'axios';
import getMe from './getMe' 
import { get } from 'lodash';
export const sendUserIPLog = async ({ topicTitle, topicId, sessionStatus = '' }) => {
    const { id: userId, schoolName, classroomTitle, role } = getMe()
    const appName = role === 'mentee' ? 'student_app' : 'teacher_app'
    const ipLogData = {
        platform: "web",
        appId: appName,
        role,
        classroomTitle,
        topicTitle,
        topicId,
        schoolName: schoolName,
        tekieUserId: userId,
        sessionStatus,
        applicationName: appName
    }
    const ipLogRes = await axios.post(`${process.env.REACT_APP_UOLO_REST_API}/core/v1/log-user-ip?source=tekie`, ipLogData)
    if (!get(ipLogRes, 'data.data.ok')) {
        console.log('Unable to log IP')
        return false;
    }
    return true;
};