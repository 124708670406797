import React from 'react'

const StarFilled = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9452 7.55776C20.8077 7.13517 20.4306 6.83502 19.9844 6.79506L13.9232 6.24811L11.5265 0.673057C11.3497 0.264478 10.9473 0 10.5001 0C10.0529 0 9.65043 0.264478 9.47371 0.674012L7.07696 6.24811L1.01484 6.79506C0.569423 6.83598 0.193224 7.13517 0.0549532 7.55776C-0.0833176 7.98035 0.0443786 8.44386 0.381323 8.73605L4.96284 12.7292L3.61186 18.6434C3.513 19.0782 3.68284 19.5277 4.0459 19.7885C4.24105 19.9287 4.46936 20 4.6996 20C4.89812 20 5.09503 19.9468 5.27175 19.8417L10.5001 16.7363L15.7265 19.8417C16.1089 20.0704 16.591 20.0495 16.9533 19.7885C17.3165 19.5269 17.4862 19.0773 17.3873 18.6434L16.0364 12.7292L20.6179 8.73684C20.9548 8.44386 21.0835 7.98115 20.9452 7.55776Z" fill="#FFC107"/>
        </svg>
    )
}

export default StarFilled
