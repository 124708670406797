/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react'
import styles from "./updatedProfileModal.module.scss";
import { BackArrow, CloseCircle, SettingIcon, XCircle } from '../../../constants/icons'
import { get } from 'lodash';
import Button from '../../../pages/TeacherApp/components/Button/Button';
import { updateUserDetails } from '../../../queries/updateUserDetails';


const UpdateProfileModal = ({setModalVisibility,modalRef,...props}) => {
    const userData = get(props, 'user').toJS();
    const userName = get(userData, 'name');
    const userEmail = get(userData, 'email');
    const [isPasswordEditClicked, setIsPasswordEditClicked] = useState(false)
    const [isEmailEditClicked, setIsEmailEditClicked] = useState(false)
    const [passwordError, setPasswordError] = useState('')
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    });
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          // Close the modal
          setModalVisibility(false)
        }
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleEmailChange = (event) => {
        setNewEmail(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }
    const saveChangesClick = async() => {
        if(password !== confirmPassword){
            setPasswordError('Passwords don’t match')
        }else if(!password){
            setPasswordError('Passwords must be at least one character')
        }else{
            const newPassword = { password } 
            const savedPassword = { savedPassword: password }
            const email = { email: userEmail}
            const updatePassword = {
                ...newPassword, ...savedPassword, ...email,
              }
            await  updateUserDetails(updatePassword, get(userData,'id'))
            setIsPasswordEditClicked(false)
        }
    }

    const saveChangesClickForEmail = async() => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const checkError  = re.test(String(newEmail).toLowerCase())
        if(userEmail === newEmail) {
            setEmailError('Same email. Enter a different one.')
            return
        }
        if(!checkError){
            setEmailError('Invalid Email ID')
            return
        }else{
            const email = { email: newEmail}
            const updateUserInput = { ...email }
            const res = await updateUserDetails(updateUserInput, get(userData,'id'))
            if(Object.keys(res).length === 0){
                setEmailError('Email ID is already taken')
            }else{
                setIsEmailEditClicked(false)
            }
        }
    }

    const backButtonClick = () => {
        isPasswordEditClicked && setIsPasswordEditClicked(false)
        isEmailEditClicked && setIsEmailEditClicked(false)
    }
    const passwordButtonClick = () => {
        setIsPasswordEditClicked(true)
    }
    const emailButtonClick = () => {
        setIsEmailEditClicked(true)
    }
    return <>
        <div className={styles.modalPopup} ref={modalRef}>
            <div className={styles.attendanceText}>
                <div className={styles.profileText}>
                    {(!isPasswordEditClicked && !isEmailEditClicked )? <SettingIcon />: <div onClick={backButtonClick} style={{cursor:'pointer'}}><BackArrow /></div>}
                    <p style={{color:'#000'}}>{isPasswordEditClicked ? "Change Password" : (isEmailEditClicked ? "Change Email" : "My Profile")}</p>
                </div>
                <div style={{cursor:'pointer'}} onClick={() => setModalVisibility(false)}>
                    <CloseCircle height='20' width='20' color='#a27fd5' />
                </div>
            </div>
            {(userName && !isPasswordEditClicked && !isEmailEditClicked) ? 
            <div className={styles.usernameContainer}>
                <p>Username</p>
                <div className={styles.content}>
                    <p>{userName}</p>
                </div>
            </div>: ''}
            
            {(!isPasswordEditClicked && !isEmailEditClicked) ?<div className={styles.usernameContainer}>
                <div className={styles.buttonContainer}>
                    <p>Email ID</p>
                    <h2 style={{cursor:'pointer'}} onClick={emailButtonClick}>Edit</h2>
                </div>
                <div className={styles.content}>
                    <p>{userEmail}</p>
                </div>
            </div> : ''}
            {(!isPasswordEditClicked && !isEmailEditClicked) ? <div className={styles.usernameContainer}>
                <div className={styles.buttonContainer}>
                    <p>Password</p>
                    <h2 style={{cursor:'pointer'}} onClick={passwordButtonClick}>Edit</h2>
                </div>
                <div className={styles.content}>
                    <p>******</p>
                </div>
            </div>: ''}
            {isPasswordEditClicked ?
                <>
                    <div className={styles.usernameContainer}>
                        <p>Password</p>
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            className={styles.inputClass}
                        />
                    </div>
                    <div className={styles.usernameContainer}>
                        <p>Confirm Password</p>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            className={styles.inputClass}
                        />
                    </div>
                    {passwordError ? 
                    <div className={styles.passwordError}>
                        <XCircle /> <span>{passwordError}</span>
                    </div>: ''}
                    <div className={styles.saveChangesButton} onClick={saveChangesClick}>
                        <Button type='primary' text='Save Changes' ></Button>
                    </div>
                </>
            : ''}
            {isEmailEditClicked ? 
            <>
                <div className={styles.usernameContainer}>
                    <p>New Email ID</p>
                    <input
                        type="email"
                        value={newEmail}
                        onChange={handleEmailChange}
                        className={styles.inputClass}
                    />
                </div>
                {emailError ? 
                    <div className={styles.passwordError}>
                        <XCircle /> <span>{emailError}</span>
                    </div>: ''}
                <div className={styles.saveChangesButton} onClick={saveChangesClickForEmail}>
                    <Button type='primary' text='Save Changes' ></Button>
                </div>
            </>
            : ''}
        </div>
        <div className={styles.modalOverlay}></div>
    </>
} 

export default UpdateProfileModal