const avatarsRelativePath = {
    theo: require('../../assets/theo.png'),
    zog: require('../../assets/zog.png'),
    erby: require('../../assets/erby.png'),
    tyra: require('../../assets/tyra.png'),
    zo: require('../../assets/zo.png'),
    ozzy: require('../../assets/ozzy.png'),
    auli: require('../../assets/auli.png'),
}
    
const studentProfileAvatars = ['theo', 'zog', 'tyra', 'erby', 'zo', 'ozzy', 'auli']

export { avatarsRelativePath, studentProfileAvatars }