import React, { useEffect } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Link, useHistory, useLocation } from "react-router-dom";
import fetchEbooks from "../../queries/sessions/fetchEbooks";

import styles from "./MainSideBar.module.scss";
import cx from "classnames";
import {
	ArrowLeft,
	SessionPageIcon,
	HomeworkPageIcon,
	CodePlaygroundPageIcon,
	CheatSheetPageIcon
} from "./mainSideBarIcons";
import { waitFor } from "../../utils/data-utils";
import TabDropdown from "../Dropdown/dropdown";
import { get } from "lodash";
import getMe from "../../utils/getMe";
import { getCodePlayground } from "../../utils/getCourseId";
import { ReactComponent as LearnVideoIcon } from "../../assets/learn/playButton.svg";
import { getFilterTopics } from "../../pages/LearnPage/utils";

const SESSIONS = "/sessions";
const HOMEWORK = "/homework";
const CODEPLAYGROUND = "/code-playground";
const BOOK = "/book";
const CHEATSHEET = "/cheatsheet";

const AppSideSideNavBar = props => {
	const firstRender = React.useRef(true);
	const [shouldBeMounted, setShouldBeMounted] = React.useState(true);
	const [isEbookDropdownVisible, setIsEbookDropdownVisible] = React.useState(
		false
	);
	const [ebookIds, setEbookIds] = React.useState([]);
	const [
		isMultipleLanguagesDropdownVisible,
		setIsMultipleLanguagesDropdownVisible
	] = React.useState(false);

	const history = useHistory();

	const { currentRoute, visible } = props;
	const ebookDropdownList =
		props.coursePackages && get(props.coursePackages.toJS(), "[0].ebooks", []);
	// const defaultBookId = ebookDropdownList && ebookDropdownList.length > 0 && get(ebookDropdownList, '[0].id' , '')
	// const shouldShowEbook = get(getMe(),'children[0].studentProfile.batch.shouldShowEbook',true)
	const courses =
		props.coursePackages && get(props.coursePackages.toJS(), "[0].courses", []);

	const programmingCourses =
		courses.filter(
			item =>
				get(item, "category") === "programming" ||
				get(item, "category") === "technology"
		) || [];
	const codingLanguages = [];
	programmingCourses &&
		programmingCourses.length &&
		programmingCourses.forEach(item => {
			const languages = get(item, "codingLanguages", []);
			languages &&
				languages.forEach(language => {
					const title = get(language, "value");
					if (
						title !== "Java" &&
						title !== "Csharp" &&
						title !== "Swift" &&
						title !== "Cplusplus"
					) {
						const obj = {
							title
						};
						const isAlreadyExist = codingLanguages.find(
							value => get(value, "title") === title
						);
						if (!isAlreadyExist) {
							codingLanguages.push(obj);
						}
					}
				});
		});

	const sidebar = [
		{
			title: "Sessions",
			icon: SessionPageIcon,
			iconType: "stroke",
			link: SESSIONS
		},
		{
			title: "Homework",
			icon: HomeworkPageIcon,
			iconType: "fill",
			link: HOMEWORK
		}
	];
	const loggedInUser = getMe();
	const loggedInShoolId = get(
		loggedInUser,
		"thisChild.studentProfile.school.id",
		""
	);
	const loggedInTenthGrade = get(loggedInUser, "grade").includes("10");
	if (
		(codingLanguages && codingLanguages.length) ||
		(loggedInShoolId === "cl1n44wbs001h0v4yheel549n" && loggedInTenthGrade)
	) {
		sidebar.push({
			title: (
				<>
					Code <br />
					Playground
				</>
			),
			icon: CodePlaygroundPageIcon,
			iconType: "fill",
			link: CODEPLAYGROUND,
			dropdown: codingLanguages.length > 1
		});
	}
	if (ebookDropdownList && ebookDropdownList.length > 0) {
		sidebar.push({
			title: "E-book",
			icon: SessionPageIcon,
			iconType: "stroke",
			link: BOOK,
			dropdown: true
		});
	}

	const language = getCodePlayground();
	if (language === "python") {
		sidebar.push({
			title: "Cheatsheet",
			icon: CheatSheetPageIcon,
			iconType: "stroke",
			link: CHEATSHEET
		});
	}
	// if(getFilterTopics(props.menteeCourseSyllabus.toJS(),courses).length  > 0){
	//     sidebar.push(
	//       { title: 'Learn', icon: LearnVideoIcon, iconType: 'fill', link: '/learn' }
	//     )
	// }

	useEffect(() => {
		if (visible && !shouldBeMounted) {
			setTimeout(() => {
				setShouldBeMounted(true);
			}, 100);
		} else if (!visible) {
			setShouldBeMounted(false);
		}
	}, [visible]);

	const stripLastId = url => {
		const urlArray = url.split("/");
		urlArray.pop();
		return urlArray.join("/");
	};

	const isCurrentLink = (currentLink, link) => {
		if (currentLink === link) {
			return link;
		} else return link === stripLastId(currentLink);
	};

	const redirectTo = config => {
		if (config.dropdown && get(config, "link") === BOOK) {
			// return history.push(`${config.link}/${defaultBookId}`)
		} else {
			// if (codingLanguages.length === 1 && get(codingLanguages, '[0].title') === 'CodeOrg') {
			//   return window.open('https://studio.code.org/projects/spritelab/', '_blank')
			// }
			return history.push(config.link);
		}
	};

	if (!visible) return <></>;
	return (
		<motion.div
			className={styles.flexColumn}
			style={{
				justifyContent: "space-between",
				alignItems: "center"
			}}
			initial={{
				opacity: firstRender.current ? 1 : 0
			}}
			animate={{ opacity: 1 }}
			transition={{
				delay: firstRender.current ? 0 : 0.1
			}}
		>
			<AnimateSharedLayout>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						whiteSpace: "nowrap"
					}}
				>
					{/* <div className={styles.tekieLogoDrop}></div> */}
					{sidebar.map(config => (
						<div
							onMouseEnter={() => {
								if (
									get(config, "dropdown", null) &&
									get(config, "link") === CODEPLAYGROUND
								) {
									setIsMultipleLanguagesDropdownVisible(true);
								}
								if (
									get(config, "dropdown", null) &&
									get(config, "link") === BOOK
								) {
									setIsEbookDropdownVisible(true);
								}
							}}
							onMouseLeave={() => {
								if (
									get(config, "dropdown", null) &&
									get(config, "link") === CODEPLAYGROUND
								) {
									setIsMultipleLanguagesDropdownVisible(false);
								}
								if (
									get(config, "dropdown", null) &&
									get(config, "link") === BOOK
								) {
									setIsEbookDropdownVisible(false);
								}
							}}
							onClick={() => redirectTo(config)}
							className={cx(
								styles.outSidenavItem,
								isCurrentLink(currentRoute, config.link) && styles.active
							)}
						>
							{get(config, "dropdown", null) &&
								((get(config, "link") === CODEPLAYGROUND &&
									codingLanguages.length > 1) ||
									get(config, "link") === BOOK) && (
									<>
										<TabDropdown
											visible={
												get(config, "link") === CODEPLAYGROUND
													? isMultipleLanguagesDropdownVisible
													: isEbookDropdownVisible
											}
											list={
												get(config, "link") === CODEPLAYGROUND
													? codingLanguages
													: ebookDropdownList
											}
											direction="right"
											size="big"
											currentRoute={currentRoute}
											onChange={() => {}}
											code={get(config, "link") === CODEPLAYGROUND}
										></TabDropdown>
									</>
								)}
							<div
								className={cx(
									styles.icon,
									config.iconType === "fill" ? styles.fill : styles.stroke
								)}
							>
								<config.icon />
							</div>
							<div className={styles.titleOutSideNavBar}>{config.title}</div>
							{isCurrentLink(currentRoute, config.link) && (
								<motion.div
									className={styles.activeLineIndicator}
									initial={{ right: 0 }}
									layoutId="activeIndicatorOutSideNavBar"
								></motion.div>
							)}
						</div>
					))}
				</div>
			</AnimateSharedLayout>
		</motion.div>
	);
};

export default AppSideSideNavBar;
