import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getCourseId from '../../utils/getCourseId'

const SESSION_SCHEMA = `
  topicId
  topicOrder
  topicTitle
  topicDescription
  topicThumbnailSmall {
    id
    uri
  }
  classType
  chapterId
  sessionStatus
  chapterTitle
  chapterOrder
  previousHomeworkExists
  batchSession {
    id
    sessionEndDate
  }
  course {
    id
  }
  videoIds
`
const fetchMenteeCourseSyllabus = (force = false, courseIdString = '') => {
    const courseId = getCourseId()
    return duck.createQuery({
          query : gql`
            mutation {
              menteeCourseSyllabus {
                upComingSession {
                  ${SESSION_SCHEMA}
                }
                bookedSession{
                  ${SESSION_SCHEMA}
                }
                completedSession{
                  ${SESSION_SCHEMA}
                }
                coursePackage {
                  id
                  title
                  ebooks{
                    id
                    title
                    resourceURL
      
                  }
                  thumbnail{
                    uri
                  }
                  courses {
                    id
                    category
                    ebooks{
                      id
                    }
                    codingLanguages {
                      value
                    }
                    defaultLoComponentRule {
                      componentName
                      order
                    }
                    thumbnailSmall{
                      uri
                    }
                    title
                    courseDisplayName
                  }
              }
              }
            }
          `,
          type: 'menteeCourseSyllabus/fetch',
          key: 'menteeCourseSyllabus',
          force,
          changeExtractedData: (extractedData, originalData) => {
            if (extractedData.menteeCourseSyllabus) {
              extractedData.menteeCourseSyllabus.courseId = courseId
            }
            extractedData.coursePackages = extractedData.coursePackages.map(coursePackage => {
              const coursesWithData = get(extractedData, 'courses', [])
              return {
                ...coursePackage,
                courses: get(coursePackage, 'courses', []).map(course => {
                  const thisCourseWithData = coursesWithData.find(c => c.id === course.id)
                  return thisCourseWithData
                })
              }
            })
            extractedData.course = extractedData.courses
            return extractedData;
          }
  })
} 


export default fetchMenteeCourseSyllabus
