import React from 'react'
import styles from  './MainSideBar.module.scss'

export function VideoIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none" 
      viewBox="0 0 32 22"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.124 3.444a1 1 0 01.543.89v13.333a1 1 0 01-1.582.813l-9.333-6.666a1 1 0 010-1.628l9.333-6.666a1 1 0 011.04-.076zM23.054 11l6.613 4.723V6.276L23.054 11z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.667c-.92 0-1.667.746-1.667 1.666v13.334c0 .92.746 1.666 1.667 1.666h14.667c.92 0 1.666-.746 1.666-1.666V4.332c0-.92-.746-1.667-1.666-1.667H4zM.333 4.332A3.667 3.667 0 014 .667h14.667a3.667 3.667 0 013.666 3.666v13.334a3.667 3.667 0 01-3.666 3.666H4a3.667 3.667 0 01-3.667-3.666V4.332z"
      />
    </svg>
  );
}


export function loIcon(props) {
  return (
    <svg width='100%' height='100%' viewBox="0 0 34 34" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.92 10.08a1 1 0 010 1.415l-7.34 7.34a1 1 0 01-1.415-1.414l7.34-7.34a1 1 0 011.415 0z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.489.076a1 1 0 01.617.924v3.894H33a1 1 0 01.707 1.707l-4.894 4.894a1 1 0 01-.707.292h-4.893a1 1 0 01-1-1V5.894a1 1 0 01.293-.707L27.399.293a1 1 0 011.09-.217zm-4.276 6.232v3.48h3.48l2.893-2.894h-2.48a1 1 0 01-1-1v-2.48l-2.893 2.894zM15.968 12.323a5.71 5.71 0 100 11.419 5.71 5.71 0 000-11.42zm-7.71 5.71a7.71 7.71 0 1115.42 0 7.71 7.71 0 01-15.42 0z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.968 4.064A13.938 13.938 0 002 18.032C2 25.77 8.23 32 15.968 32c7.737 0 13.968-6.23 13.968-13.968 0-2.887-.422-5.067-1.774-7.147a1 1 0 011.676-1.09c1.642 2.525 2.098 5.137 2.098 8.237A15.938 15.938 0 0115.968 34 15.938 15.938 0 010 18.032 15.938 15.938 0 0115.968 2.064c3.213 0 5.636.878 8.077 2.465a1 1 0 01-1.09 1.677c-2.164-1.407-4.214-2.142-6.987-2.142z"
      />
    </svg>
  )
}

export function comicStripIcon(props) {
  return (
    <svg width='100%' height='100%' viewBox="0 0 24 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.111 7.8a.311.311 0 00-.311.311v7.778c0 .172.14.311.311.311h7.778c.172 0 .311-.14.311-.311V8.11a.311.311 0 00-.311-.311H8.11zM6.2 8.111c0-1.055.856-1.911 1.911-1.911h7.778c1.055 0 1.911.856 1.911 1.911v7.778a1.911 1.911 0 01-1.911 1.911H8.11A1.911 1.911 0 016.2 15.889V8.11z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.833 9.5a.333.333 0 100 .667.333.333 0 000-.667zM8.5 9.833a1.333 1.333 0 112.667 0 1.333 1.333 0 01-2.667 0zM13.07 10.434a.8.8 0 011.109-.021l2.864 2.651a.8.8 0 11-1.086 1.175l-2.3-2.13-5.091 5.092a.8.8 0 01-1.132-1.131l5.636-5.636z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.667 22.667A2.334 2.334 0 002.333 25a1 1 0 01-2 0 4.334 4.334 0 014.334-4.333h18a1 1 0 110 2h-18z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.667 2.667A2.333 2.333 0 002.333 5v20a2.333 2.333 0 002.334 2.333h17V2.667h-17zm0-2h18a1 1 0 011 1v26.666a1 1 0 01-1 1h-18A4.334 4.334 0 01.333 25V5A4.333 4.333 0 014.667.667z"
      />
    </svg>
  );
}

export function ChatIcon(props) {
  return (
    <svg viewBox='0 0 28 26' width='100%' height='100%' fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.497 0H23.5v1l.003-1A4.51 4.51 0 0128 4.497v12.006A4.51 4.51 0 0123.503 21H13.36l-5.72 4.768A1 1 0 016 25v-4H4.497A4.51 4.51 0 010 16.503V4.497A4.51 4.51 0 014.497 0zM13.5 20.884h-.001zM23.498 2H4.502A2.51 2.51 0 002 4.502v11.997A2.51 2.51 0 004.502 19H7a1 1 0 011 1v2.864l4.216-3.515h.001a1.5 1.5 0 01.961-.349h10.32A2.51 2.51 0 0026 16.498V4.502A2.51 2.51 0 0023.498 2z"
      />
      <path
        d="M8 12.5a2 2 0 100-4 2 2 0 000 4zM14 12.5a2 2 0 100-4 2 2 0 000 4zM20 12.5a2 2 0 100-4 2 2 0 000 4z"
      />
    </svg>
  );
}


export function learningSlideIcon(props) {
  return (
    <svg width='100%' height='100%' viewBox="0 0 40 34" >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.72729C0 9.17501 0.447715 8.72729 1 8.72729H39C39.5523 8.72729 40 9.17501 40 9.72729C40 10.2796 39.5523 10.7273 39 10.7273H1C0.447715 10.7273 0 10.2796 0 9.72729Z" />
      <path d="M24.5673 6.81815C24.1288 6.81815 23.8365 6.67269 23.5442 6.38178C23.2519 6.09088 23.1057 5.79997 23.1057 5.3636C23.1057 4.92724 23.2519 4.63633 23.5442 4.34542L23.6903 4.19997C23.8365 4.19997 23.8365 4.05451 23.9826 4.05451C24.1288 3.90906 24.1288 3.90906 24.2749 3.90906C24.4211 3.90906 24.7134 3.90906 24.8596 3.90906C25.0057 3.90906 25.0057 3.90906 25.1519 4.05451C25.298 4.05451 25.298 4.19997 25.4442 4.19997L25.5903 4.34542C25.7365 4.49088 25.8826 4.63633 25.8826 4.78178C26.0288 4.92724 26.0288 5.21815 26.0288 5.3636C26.0288 5.50906 26.0288 5.79997 25.8826 5.94542C25.8826 6.09088 25.7365 6.23633 25.5903 6.38178C25.298 6.67269 25.0057 6.81815 24.5673 6.81815Z" />
      <path d="M28.952 6.81806C28.5136 6.81806 28.2212 6.67261 27.9289 6.3817C27.6366 6.09079 27.4905 5.79988 27.4905 5.36352C27.4905 5.21806 27.4905 4.92715 27.6366 4.7817C27.7828 4.63624 27.7828 4.49079 27.9289 4.34533C28.0751 4.19988 28.2212 4.05442 28.3674 4.05442C28.952 3.76352 29.5366 3.90897 29.9751 4.34533C30.1212 4.49079 30.2674 4.63624 30.2674 4.7817C30.4136 4.92715 30.4136 5.21806 30.4136 5.36352C30.4136 5.79988 30.2674 6.09079 29.9751 6.3817C29.6828 6.67261 29.3905 6.81806 28.952 6.81806Z" />
      <path d="M33.3365 6.81815C33.1904 6.81815 32.8981 6.81815 32.7519 6.67269C32.6058 6.52724 32.4596 6.52724 32.3135 6.38178C32.1673 6.23633 32.0212 6.09087 32.0212 5.94542C31.875 5.79997 31.875 5.50906 31.875 5.3636C31.875 5.21815 31.875 4.92724 32.0212 4.78178C32.1673 4.63633 32.1673 4.49088 32.3135 4.34542C32.8981 3.7636 33.775 3.7636 34.3596 4.34542C34.5058 4.49088 34.6519 4.63633 34.6519 4.78178C34.7981 4.92724 34.7981 5.21815 34.7981 5.3636C34.7981 5.50906 34.7981 5.79997 34.6519 5.94542C34.5058 6.09087 34.5058 6.23633 34.3596 6.38178C34.0673 6.67269 33.775 6.81815 33.3365 6.81815Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.90909C0 1.75229 1.76763 0 3.92308 0H36.0769C38.2324 0 40 1.75229 40 3.90909V30.0909C40 32.2477 38.2324 34 36.0769 34H3.92308C1.76763 34 0 32.2477 0 30.0909V3.90909ZM3.92308 2C2.86314 2 2 2.86589 2 3.90909V30.0909C2 31.1341 2.86314 32 3.92308 32H36.0769C37.1369 32 38 31.1341 38 30.0909V3.90909C38 2.86589 37.1369 2 36.0769 2H3.92308Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.401 16.2946C13.7906 16.6861 13.7891 17.3192 13.3976 17.7088L9.72523 21.3636L13.3976 25.0185C13.7891 25.4081 13.7906 26.0412 13.401 26.4327C13.0114 26.8241 12.3783 26.8257 11.9868 26.4361L7.60221 22.0724C7.41363 21.8848 7.30762 21.6297 7.30762 21.3636C7.30762 21.0976 7.41363 20.8425 7.60221 20.6548L11.9868 16.2912C12.3783 15.9016 13.0114 15.9031 13.401 16.2946Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5988 16.2946C26.9884 15.9031 27.6216 15.9016 28.013 16.2912L32.3976 20.6548C32.5862 20.8425 32.6922 21.0976 32.6922 21.3636C32.6922 21.6297 32.5862 21.8848 32.3976 22.0724L28.013 26.4361C27.6216 26.8257 26.9884 26.8241 26.5988 26.4327C26.2092 26.0412 26.2107 25.4081 26.6022 25.0185L30.2746 21.3636L26.6022 17.7088C26.2107 17.3192 26.2092 16.6861 26.5988 16.2946Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.372 14.6518C23.8655 14.8998 24.0645 15.5008 23.8166 15.9943L17.9704 27.6307C17.7225 28.1242 17.1214 28.3233 16.6279 28.0753C16.1344 27.8274 15.9354 27.2263 16.1833 26.7328L22.0295 15.0965C22.2774 14.6029 22.8785 14.4039 23.372 14.6518Z" />
    </svg>
  )
}

export function pqIcon(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 39 34" fill="none" >
      <path
        clipRule="evenodd"
        d="M26.354 1.23A4.197 4.197 0 0023.385 0H12.193a1 1 0 00-.708.293L.293 11.493A1 1 0 000 12.2v17.6A4.201 4.201 0 004.198 34h19.187a4.197 4.197 0 004.198-4.2V19h-2v10.8a2.201 2.201 0 01-2.198 2.2H4.198A2.197 2.197 0 012 29.8V12.614L12.607 2h10.778a2.197 2.197 0 012.198 2.2V11h2V4.2a4.201 4.201 0 00-1.23-2.97z"
      />
      <path
        clipRule="evenodd"
        d="M12.192 0a1 1 0 00-1 1v10.2H1a1 1 0 100 2h11.192a1 1 0 001-1V1a1 1 0 00-1-1zM28.937 7.625a1 1 0 01.71.289l4.05 4.003a1 1 0 01.009 1.413L20.984 26.246a.999.999 0 01-.447.262l-5.48 1.506a1 1 0 01-1.232-1.216l1.43-5.508a1 1 0 01.255-.45L28.23 7.922a1 1 0 01.706-.298zm.016 2.415l-11.83 12.012-.928 3.575 3.553-.976L31.58 12.637l-2.627-2.597z"
      />
      <path
        clipRule="evenodd"
        d="M16.145 20.394a1 1 0 01.96.258l4.038 4.014a1 1 0 01-.42 1.668l-5.646 1.674a1 1 0 01-1.206-1.346l1.566-5.571a1 1 0 01.708-.697zm.11 5.179l2.282-.677-1.636-1.626-.646 2.303zM30.682 5.853a1 1 0 01.71.289l4.05 4.003a1 1 0 01.008 1.413l-1.63 1.655a1 1 0 01-1.415.01l-4.05-4.004a1 1 0 01-.009-1.413l1.63-1.655a1 1 0 01.706-.298zm-.21 2.645l2.627 2.598.226-.23-2.627-2.597-.227.23z"
      />
      <path
        clipRule="evenodd"
        d="M34.547 6.007a1.152 1.152 0 00-1.632.011l-.813.826 2.627 2.597.81-.823.713.701-.713-.701a1.163 1.163 0 00-.01-1.64l.703-.712-.703.712-.982-.971zm1.406-1.422l-.7.708.7-.708.982.97a3.163 3.163 0 01.03 4.466l-1.514 1.537a1 1 0 01-1.416.01l-4.05-4.004a1 1 0 01-.009-1.413l1.514-1.536.61.601-.61-.601a3.152 3.152 0 014.463-.03z"
      />
    </svg>
  )
}

export function reportIcon(props) {
  return (
    <svg width='100%' height='100%' viewBox="0 0 22 26" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.667 0a1 1 0 011 1v5.333a.333.333 0 00.333.334h5.333a1 1 0 110 2H15a2.333 2.333 0 01-2.333-2.334V1a1 1 0 011-1z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.333 2a1.667 1.667 0 00-1.666 1.667v18.666A1.666 1.666 0 004.333 24h13.334a1.667 1.667 0 001.666-1.667V8.081L13.253 2h-8.92zm-2.592-.926A3.667 3.667 0 014.333 0h9.334a1 1 0 01.707.293L21.04 6.96a1 1 0 01.292.707v14.666A3.667 3.667 0 0117.667 26H4.333a3.667 3.667 0 01-3.666-3.667V3.667c0-.973.386-1.905 1.074-2.593z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12a1 1 0 011 1v6.667a1 1 0 11-2 0V13a1 1 0 011-1zM11 17.333a1 1 0 011 1v1.334a1 1 0 11-2 0v-1.334a1 1 0 011-1zM15 14.667a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1z"
      />
    </svg>
  );
}

export function CodingIcon(props) {
  return (
    <svg width='100%' height='100%' viewBox="0 0 28 34" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.23 1.23A4.197 4.197 0 014.197 0H15.39a1 1 0 01.708.293l11.192 11.2a1 1 0 01.293.707v17.6a4.201 4.201 0 01-4.198 4.2H4.198A4.197 4.197 0 010 29.8V4.2c0-1.114.442-2.182 1.23-2.97zM2 29.8A2.201 2.201 0 004.198 32h19.187a2.197 2.197 0 002.198-2.2V12.614L14.976 2H4.198A2.197 2.197 0 002 4.2v25.6z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.39 0a1 1 0 011 1v10.2h10.193a1 1 0 110 2H15.391a1 1 0 01-1-1V1a1 1 0 011-1zM10.707 17.995a1 1 0 010 1.415l-2.293 2.292 2.293 2.293a1 1 0 01-1.414 1.415l-3-3a1 1 0 010-1.415l3-3a1 1 0 011.414 0zM17.072 17.995a1 1 0 011.414 0l3 3a1 1 0 010 1.415l-3 3a1 1 0 01-1.414-1.415l2.293-2.293-2.293-2.293a1 1 0 010-1.414zM15.833 16.048a1 1 0 01.648 1.257l-3.124 9.759a1 1 0 11-1.905-.61l3.124-9.759a1 1 0 011.257-.647z"
      />
    </svg>
  );
}

export function AssginmentIcon(props) {
  return (
    <svg width='100%' height='100%' viewBox='0 0 25 37' fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.614.29a5.012 5.012 0 015.056 5.057v.318l5.61-.011a1 1 0 011.001 1.002l-.017 8.125a1 1 0 01-.998.998l-1.322.003-.044-.001c-1.564-.065-2.931 1.237-2.935 2.98v.043c-.072 1.56 1.219 2.917 2.958 2.914l1.323-.003a1 1 0 011.002 1.002l-.017 7.936a1 1 0 01-.998.998l-5.617.012-.001.327c-.006 2.785-2.233 5.092-5.202 4.788-2.746.078-4.929-2.196-4.923-4.964v-.319l-5.609.012A1 1 0 01.88 30.505l.017-7.936a1 1 0 01.998-.998l1.323-.003h.043c1.564.066 2.931-1.237 2.935-2.979l.001-.043c.071-1.561-1.22-2.918-2.959-2.914l-1.322.002a1 1 0 01-1.002-1.002l.016-7.936a1 1 0 01.984-.998l5.726-.092.001-.32C7.538 2.515 9.829.296 12.614.29zm3.056 5.053a3.013 3.013 0 00-3.052-3.052c-1.742.003-3.044 1.37-2.979 2.935l.001.043-.003 1.323a1 1 0 01-.984.998l-5.726.092-.012 5.95h.318c2.789-.006 5.076 2.208 4.962 4.983-.017 2.776-2.231 5.054-4.995 4.953h-.306l-.013 5.937 5.61-.012a1 1 0 011.002 1.002l-.003 1.323c-.004 1.739 1.353 3.03 2.914 2.958.055-.002.11 0 .163.006 1.714.203 3.044-1.072 3.048-2.796l.003-1.323a1 1 0 01.998-.998l5.617-.012.012-5.936h-.318c-2.789.007-5.076-2.208-4.962-4.983.017-2.775 2.231-5.054 4.995-4.952l.306-.001.013-6.125-5.61.012a1 1 0 01-1.001-1.003l.003-1.322z"
      />
    </svg>
  );
}
export function QuizIcon(props) {
  return (
    <svg viewBox="0 0 24 30" width="100%" height="100%" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.667 22.667A2.334 2.334 0 002.333 25a1 1 0 01-2 0 4.334 4.334 0 014.334-4.333h18a1 1 0 110 2h-18z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.667 2.667A2.333 2.333 0 002.333 5v20a2.333 2.333 0 002.334 2.333h17V2.667h-17zm0-2h18a1 1 0 011 1v26.666a1 1 0 01-1 1h-18A4.334 4.334 0 01.333 25V5A4.333 4.333 0 014.667.667z"
      />
      <path
        d="M10.632 13.688l-.064-2.608h.608c.576 0 1.104-.053 1.584-.16.49-.107.88-.315 1.168-.624.299-.31.448-.768.448-1.376 0-.587-.181-1.045-.544-1.376-.363-.341-.837-.512-1.424-.512-.608 0-1.088.165-1.44.496-.352.33-.528.779-.528 1.344h-1.6c-.01-.64.128-1.205.416-1.696.299-.49.715-.875 1.248-1.152.544-.277 1.179-.416 1.904-.416.704 0 1.328.133 1.872.4s.966.65 1.264 1.152c.31.49.464 1.083.464 1.776 0 .757-.165 1.381-.496 1.872-.33.48-.784.837-1.36 1.072-.576.224-1.237.336-1.984.336l-.048 1.472h-1.488zm.752 3.408c-.33 0-.597-.101-.8-.304a1.056 1.056 0 01-.304-.752c0-.299.102-.55.304-.752.203-.213.47-.32.8-.32.31 0 .566.107.768.32.214.203.32.453.32.752 0 .288-.106.539-.32.752a1.041 1.041 0 01-.768.304z"
      />
    </svg>
  );
}

export function ArrowLeft(props) {
  return (
    <svg width='100%' height='100%' viewBox='0 0 28 28' fill="none" {...props}>
      <path
        d="M22.167 14H5.833M14 22.167L5.833 14 14 5.833"
        stroke="#111"
        strokeWidth={2.545}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}



export function SessionPageIcon(props) {
  return (
    <svg viewBox="0 0 32 28" fill="none" {...props}>
      <path
        d="M15.998 8v18m0-18c1-3.947 4.776-5.962 13-6a.996.996 0 011 1v18a1 1 0 01-1 1c-8 0-11.091 1.614-13 4-1.899-2.374-5-4-13-4-.618 0-1-.502-1-1.12V3a.997.997 0 011-1c8.223.038 12 2.053 13 6z"
        strokeWidth={2.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HomeworkPageIcon(props) {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7a2 2 0 012-2h2a2 2 0 012 2v21a2 2 0 01-2 2H3a2 2 0 01-2-2V7zm4 0H3v21h2V7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14a1 1 0 011-1h8a1 1 0 110 2H7a1 1 0 01-1-1zM6 25a1 1 0 011-1h8a1 1 0 110 2H7a1 1 0 01-1-1z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11a2 2 0 012-2h6a2 2 0 012 2v17a2 2 0 01-2 2H8a2 2 0 01-2-2V11zm8 0H8v17h6V11z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4a2 2 0 012-2h4a2 2 0 012 2v24a2 2 0 01-2 2h-4a2 2 0 01-2-2V4zm6 0h-4v24h4V4z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3 5.014c1.28-.135 2.367.833 2.506 2.062v.005l2.18 20.096c.134 1.235-.729 2.412-2.01 2.546l-2.525.266h-.001c-1.276.133-2.369-.83-2.503-2.063l-2.183-20.1c-.134-1.235.729-2.412 2.01-2.547l2.525-.265zm.519 2.286c-.026-.217-.2-.309-.31-.297l-2.526.265c-.108.012-.253.135-.23.342l2.182 20.098c.022.208.193.303.307.292l2.525-.266c.108-.011.253-.134.23-.341L26.82 7.3V7.3z"
      />
    </svg>
  );
}

export function CodePlaygroundPageIcon(props) {
  return (
    <svg viewBox="0 0 33 33" fill="none" {...props}>
      <circle
        cx={16.5}
        cy={16.001}
        r={15.001}
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.219 11.213a.625.625 0 01-.059.882l-4.462 3.904 4.462 3.905a.625.625 0 11-.823.94l-5-4.374a.625.625 0 010-.941l5-4.375a.625.625 0 01.882.059zM19.778 11.213a.625.625 0 01.882-.059l5 4.375a.625.625 0 010 .94l-5 4.375a.625.625 0 11-.823-.94l4.462-3.905-4.462-3.904a.625.625 0 01-.06-.882zM18.553 9.15c.33.1.518.449.42.78l-3.75 12.498a.625.625 0 11-1.198-.359l3.75-12.499c.1-.33.448-.518.778-.42z"
      />
    </svg>
  );
}

export function CheatSheetPageIcon(props) {
	return (
		<svg viewBox="0 0 34 37" fill="none" {...props}>
			<path
				d="M17.332 3.168H7.999a2.667 2.667 0 00-2.667 2.667v21.333a2.667 2.667 0 002.667 2.667h16a2.667 2.667 0 002.666-2.667V12.501l-9.333-9.333z"
				stroke="#A8A7A7"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.332 3.168v9.333h9.333"
				stroke="#A8A7A7"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 24.5s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
				fill="#fff"
				stroke="#A8A7A7"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M22 27.5a3 3 0 100-6 3 3 0 000 6z" fill="#A8A7A7" className={styles.replaceFill} />
		</svg>
	);
}