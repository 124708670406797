import get from "lodash/get"
import config, { learningObjectiveComponents } from "../../../config"
import duck from "../../../duck"
import fetchLiveSession from "../../../queries/fetchLiveSession"
import verifyOTP from "../../../queries/SchoolLIveLoginInput"
import { getAllSessions, getThisSession, } from "../../UpdatedSessions/utils"
import requestToGraphql from "../../../utils/requestToGraphql"
import { getSlotDifference, getTimeRangeFromSession } from "../../../utils/teacherApp/mapQueryResponseToFullCalendarEvents"
import gql from "graphql-tag"
import { buddyQueryTracker } from "../../../constants/buddyLogin"
import { sessionStatus as sessionStatusValues } from '../../TeacherApp/pages/TimeTable/constants'
import startBatchSession from "../../../queries/teacherApp/startBatchSession"
import fetchMenteeCourseSyllabus from "../../../queries/sessions/fetchMenteeCourseSyllabus"
import fetchComponents from "../../../queries/fetchComponents"
import { sortBy } from "lodash"
import mixpanelTrack from "../../../utils/mixpanel-actions"
import { appNames, mixPanelEvents } from "../../../constants/mixpanel/mixPanelConst"
import redirectByUserType from "../../../utils/redirectByUserType"
import { TOPIC_COMPONENTS } from "../../../constants/topicComponentConstants"
import store from "../../../store"
import { filterKey } from "../../../utils/data-utils"
import getSystemId from "../../../utils/getOrGenerateSystemId"
import { sendUserIPLog } from "../../../utils/ip-log"

export const verifyOTPHandler = async (userId, buddyLoginInput = [], setIsLoading) => {
    let verifyOTPStatus = await verifyOTP(userId, '', setIsLoading, buddyLoginInput)
    return verifyOTPStatus
}

const updatedMarkAttendance = (sessionId, studentProfileIds) => {
    requestToGraphql(gql`{
    getBuddyStatus(sessionId:"${sessionId}", studentIds:${studentProfileIds}, action:"markAttendance"){
        error
        result
    }
    }`)
    return true;
}

export const logoutBuddies = (sessionId, studentProfileIds) => {
    requestToGraphql(gql`{
    getBuddyStatus(sessionId:"${sessionId}", studentIds:${studentProfileIds}, action:"logout", systemId:"${getSystemId()}"){
        error
        result
    }
    }`, {}, undefined, 'appTokenOnly')
    return true;
}

const startBatchSessionStatus = async (batchSessionId) => {
    const data = await requestToGraphql(`mutation {
    updateBatchSession(id: "${batchSessionId}", input:{
        sessionStatus:started
    }) {
        id
        sessionStatus
    }
    }
    `)
    return data;
}

export const renderHeader = (type, step,isStudentSelected,isAlreadyPresentInSystem,buddyTeamListLength, isRollNoAutoGenerated) => {
    if (type)
        return 'Login'
    switch (step) {
        case 0:
            return 'Enter Class OTP below to join'
        case 1:
            if(isStudentSelected) return 'All set to go?'
            if(isAlreadyPresentInSystem) return 'Confirm Password'
            return buddyTeamListLength===0?'Enter your Name/Roll Number':'Enter Buddy #'+ (buddyTeamListLength+1) + "'s " + (isRollNoAutoGenerated ? 'Name' : 'Roll Number')
        case 2:
            return 'Choose your name'
        default:
            return
    }
}

export const loginChangeType = (loginWithEmail) => {
    switch (loginWithEmail) {
        case true:
            return 'OTP'
        case false:
            return 'email'
        default:
            return
    }
}

const routeToUpdatedSessions = ({
    history,
    sidebarRule = [],
    state = {}, startTime }) => {
        if (sidebarRule.length) {
            const firstComponent = sidebarRule[0]
            mixpanelTrack(mixPanelEvents.OTP_LOGIN, { startTime, endTime: new Date() }, appNames.STUDENT_APP)
            if ((get(firstComponent, 'type') === TOPIC_COMPONENTS.learningObjective || 
                Object.keys(learningObjectiveComponents).includes(get(firstComponent, 'type')))
                && get(firstComponent, 'childComponents', []).length) {
                const firstChildComponentLink = get(firstComponent, 'childComponents[0].link')
                if (firstChildComponentLink) {
                    history.push({
                        pathname: firstChildComponentLink,
                        state
                    })
                    return true
                }
            } else if (get(firstComponent, 'link')) {
                history.push({
                    pathname: get(firstComponent, 'link'),
                    state
                })
                return true
            }
        }
        // let redirectUrl = ''
        // const { firstComponentName, childComponentName, componentId, ...restObj } = getInSessionRoute({
        //     topicComponentRule, course, topicId: currentSessionTopicId
        // })
        // redirectUrl = restObj.redirectUrl
        // if (prevTopicComponentRule.length || previousHomeworkExists) {
        //     const isQuizExist = prevTopicComponentRule.find(component => get(component, 'componentName') === 'quiz')
        //     if (isQuizExist || previousHomeworkExists) {
        //         // state = {
        //         //     ...state,
        //         //     firstComponent: {
        //         //         componentName: firstComponentName,
        //         //         childComponentName,
        //         //         componentId
        //         //     },
        //         //     quizReportTopicId
        //         // }
        //         const homeworkRedirectUrl = getHomeworkRoute({
        //             topicComponentRule: prevTopicComponentRule,
        //             course,
        //             topicId: currentSessionTopicId,
        //             showReportIfExist: true
        //         })
        //         if (homeworkRedirectUrl) {
        //             redirectUrl = homeworkRedirectUrl
        //         }
        //     }
        // }
        // history.push({
        //     pathname: redirectUrl,
        //     state
        // })
        return true
    }

const getBuddyQueryTrackerObject=(buddyList=[])=>{
    const buddies=buddyList.filter(buddy=>!get(buddy,'isPrimaryUser'))
    const trackerObj={}
    buddies.forEach(buddy=>{
        trackerObj[get(buddy,'id')]=buddyQueryTracker
    })
    return trackerObj
}

const fetchMenteeCourseSyllabusData = async (currentSessionTopicId) =>{
    let prevSessionTopicId;
    let previousHomeworkExists = false;
    let topicDetail = getThisSession(currentSessionTopicId)
    if (!topicDetail) {
        await fetchMenteeCourseSyllabus().call()
        topicDetail = getThisSession(currentSessionTopicId)
    }
    const allSessions = sortBy(getAllSessions(), 'topicOrder')
    const currentTopicIndex = allSessions.findIndex(session => get(session, 'topicId') === currentSessionTopicId)
    if (currentTopicIndex !== -1) {
        const prevTopicDetail = allSessions[currentTopicIndex - 1]
        if (prevTopicDetail) {
            prevSessionTopicId = get(prevTopicDetail, 'topicId')
            previousHomeworkExists = get(topicDetail, 'previousHomeworkExists', false)
        }
    }
    return {
        prevSessionTopicId,
        previousHomeworkExists
    }
}

export const fetchComponentDetails = async ({ courseId, topicId, user, code, sessionId }) =>{
    fetchLiveSession({
        batchId: get(user, 'studentProfile.batch.id'),
        code,
        sessionId,
    })
    fetchComponents(
        topicId,
        courseId
    ).classwork()
    fetchMenteeCourseSyllabusData(topicId)
}

export const moveToSession = async (data, override, history, code, setIsLoading, fromOtpScreen, { 
    isBuddyLoginEnabled, sessionId, startTime, courseId, topicId , bookId, topicTitle
}) => {
    let user =  filterKey(store.getState().data.getIn(["user", "data"]), "loggedinUser").get(0)
    user = user && user.toJS()
    let course
    let currentSessionTopicId
    let prevTopicComponentRule = []
    let role = get(data, 'role')
    if (get(user, 'isMentorLoggedIn')) {
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    if (role !== config.MENTEE && role !== config.PARENT) {
        setIsLoading(false)
        return false
    }
    if (!fromOtpScreen) {
        const shouldRedirect = await redirectByUserType(user)
        if (!shouldRedirect) history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    if (!get(user, 'studentProfile.batch.id')) {
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    sendUserIPLog({ topicTitle, topicId })
    const sidebarRule = await fetchComponents(topicId, courseId).sidebar()
    if (!sidebarRule || !sidebarRule.length) {
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    await fetchMenteeCourseSyllabusData(topicId)
    fetchLiveSession({
        batchId: get(user, 'studentProfile.batch.id'),
        code,
        sessionId,
    }).then(liveSessionDetails => {
        let sessionStatus = get(liveSessionDetails, 'sessionStatus')
        let isRetakeSession = get(liveSessionDetails, 'isRetakeSession', false)
        if (get(liveSessionDetails, 'sessionStatus') === sessionStatusValues.completed && !isRetakeSession && get(liveSessionDetails, 'schoolSessionsOtp[0].otp')
            && get(liveSessionDetails, 'retakeSessions', []).length) {
            const allottedRetakeSession = get(liveSessionDetails, 'retakeSessions', []).find(retakeSession => get(retakeSession, 'sessionStatus') === 'allotted')
            if (allottedRetakeSession) {
                isRetakeSession = true;
                startBatchSession(get(liveSessionDetails, 'id'), false, get(allottedRetakeSession, 'id'))
            }
        }
        if (liveSessionDetails) {
            const studentProfileIds = []
            if (get(user, 'studentProfile.id')) studentProfileIds.push(get(user, 'studentProfile.id'))
            if (get(user, 'buddyDetails') && get(user, 'buddyDetails', []).length) {
                currentSessionTopicId = get(liveSessionDetails, 'topic.id')
                course = get(liveSessionDetails, "course")
                localStorage.setItem('buddyQueriesTracker',JSON.stringify(getBuddyQueryTrackerObject(get(user, 'buddyDetails', []))))
                get(user, 'buddyDetails', []).forEach((buddy) => {
                    if (!studentProfileIds.includes(get(buddy, 'studentProfile.id'))) {
                        studentProfileIds.push(get(buddy, 'studentProfile.id'))
                    }
                })
            }
            if (studentProfileIds.length && get(liveSessionDetails, 'id')) {
                updatedMarkAttendance(get(liveSessionDetails, 'id'), JSON.stringify(studentProfileIds))
            }
            const { startTime: sessionStartTime } = getTimeRangeFromSession(get(liveSessionDetails, 'bookingDate'), liveSessionDetails)
            if (sessionStatus === 'allotted') {
                startBatchSessionStatus(get(liveSessionDetails, 'id'))
                sessionStatus = 'started';
            }
            if (sessionStatus === sessionStatusValues.allotted || (sessionStatus === sessionStatusValues.completed && !isRetakeSession)) {
                history.push(`/sessions`, { fromLogin: "true" })
                return null
            }
            currentSessionTopicId = get(liveSessionDetails, 'topic.id')
            course = get(liveSessionDetails, "course")
            // if (componentDetail.prevSessionTopicId) prevSessionTopicId = componentDetail.prevSessionTopicId;
            // if (componentDetail.previousHomeworkExists) previousHomeworkExists = componentDetail.previousHomeworkExists;
        }
    })
    if (override) {
        if (get(user, "id")) {
            duck.merge(
                () => ({
                    user: {
                        ...user,
                        hasVerifiedOTP: true,
                        fromOtpScreen
                    },
                }),
                { key: "loggedinUser" }
            );
        }
        routeToUpdatedSessions({
            topicComponentRule: [],
            course,
            currentSessionTopicId,
            history,
            startTime,
            sidebarRule
        })
        return null
    }
    else return false
}


export const getUserForStateLoginWithEmailOrUsername = (data, fromOtpScreen, callbackIfMultipleChildren = () => { }) => {
    if (get(data, 'studentProfile.id')) {
        return {
            user: {
                ...data,
                fromOtpScreen,
                campaign: get(data, 'campaign.type'),
                savedPassword: get(data, 'studentProfile.parents[0].user.savedPassword'),
                email: get(data, 'studentProfile.parents[0].user.email'),
                parent: {
                    ...get(data, 'studentProfile.parents[0].user'),
                    parentProfile: get(data, 'studentProfile.parents[0]'),
                },
                createdAt: get(data, 'createdAt')
            },
            studentProfile: get(data, 'studentProfile')
        }
    }
    const children = get(data, 'children')
    const childrenStudentProfile = get(data, 'parentProfile.children[0]')
    if (children.length && children.length === 1) {
        return {
            user: {
                ...children[0],
                fromOtpScreen,
                studentProfile: { ...childrenStudentProfile },
                savedPassword: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.savedPassword') : get(data, 'savedPassword'),
                campaign: get(data, 'campaign.type'),
                email: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.email') : get(data, 'email'),
                parent: data,
                createdAt: get(data, 'createdAt')
            },
            studentProfile: childrenStudentProfile
        }
    }
    if (children.length && children.length > 1) {
        if (callbackIfMultipleChildren) {
            callbackIfMultipleChildren()
        }
        return {
            userChildren: children,
            userParent: data,
            user: {
                ...children[0],
                fromOtpScreen: fromOtpScreen,
                campaign: get(data, 'campaign.type'),
                email: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.email') : get(data, 'email'),
                parent: data,
                createdAt: get(data, 'createdAt'),
                savedPassword: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.savedPassword') : get(data, 'savedPassword'),
            }
        }
    }
}