import gql from 'graphql-tag'
import duck from '../../duck'
import { getSlotNames } from '../../utils/slots/slot-names'

const batchSession = (batchSessionId) => {
  return duck.query({
    query: gql`
  { 
    batchSessionData:batchSession(id:"${batchSessionId}"){
      id
      course {
        id
        title
      }
      subSessions {
        id
        createdAt
        duration
      }
      loggedInUserStatus{
        user{
          id
          name
        }
        isLoggedIn
        systemId
      }
      sessionStartDate
      sessionEndDate
      bookingDate
      isRetakeSession
      ${getSlotNames()}
      sessionStatus
      attendance{
        student{
          id
          rollNo
          user { id name}
        }
        isPresent
        status
      }
      schoolSessionsOtp {
        id
        otp
      }
      sessionComponentTracker{
        id
        video{
          componentId
          visited
          submitted
          user{
            id
          }
      }
      learningObjective{
          componentId
          visited
          submitted
          user{
            id
          }
      }
      blockBasedPractice: practice{
          componentId
          visited
          submitted
          user{
            id
          }
      }
      assignment: assignments{
          componentId
          visited
          submitted
          user{
            id
          }
      }
      componentStatus{
          componentName
          componentStatus
      }
      }
    }
  }
  `,
    type: 'batchSessionData/fetch',
    key: 'batchSessionData',
    changeExtractedData: (extractedData, originalData) => {
      if (originalData.batchSessionData) {
        extractedData.batchSessionData = originalData.batchSessionData
      }
      if (originalData.batchSessionData) {
        return extractedData
      }
      return {
        ...extractedData,
        batchSessionData: null
      }
    }
  })
}

export default batchSession
