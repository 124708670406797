import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import LoadingSpinner from "../../pages/TeacherApp/components/Loader/LoadingSpinner";
import classes from "./button.module.scss";
import Stack from "../Stack";

const SimpleButton = React.forwardRef(
	(
		{
			className,
			icon,
			children,
			inverted = false,
			pill = false,
			text = false,
			size = "medium",
			type = "primary",
			onClick = () => {},
			disabled,
			loading = false,
			block,
			htmlType = "button",
			...props
		},
		ref
	) => (
		<button
			onClick={disabled || loading ? () => {} : onClick}
			className={classNames(
				classes.button,
				classes[size],
				{
					[classes[type]]: type,
					[classes.pill]: pill,
					[classes.disabled]: disabled || loading,
					[classes.inverted]: inverted,
					[classes.text]: text,
					[classes.block]: block,
				},
				className
			)}
			disabled={disabled || loading}
			ref={ref}
			type={htmlType}
			{...props}
		>
			<Stack direction='row' spacing={4} alignItems='center' justifyContent='center'>
			{loading ? <LoadingSpinner height="100%" color="white" /> : icon}
			<span>{children}</span>
			</Stack>
		</button>
	)
);

// prop types with jsdoc comments
SimpleButton.propTypes = {
	/**
	 * The content of the component.
	 * @type {React.ReactNode}
	 * @required
	 * @default null
	 * */
	children: PropTypes.node.isRequired,
	/**
	 * The icon of the button will be shown of the left side of the text.
	 * @type {React.ReactNode}
	 * @default null
	 * */
	icon: PropTypes.node,
	/**
	 * The type of the button
	 * @default "primary"
	 * @type {string}
	 * */
	type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
	/**
	 * If true, the button will be disabled.
	 * @type {boolean}
	 * @default false
	 * */
	disabled: PropTypes.bool,
	/**
	 * If true, the button will be inverted.
	 * @type {boolean}
	 * @default false
	 * */
	inverted: PropTypes.bool,
	/**
	 * If true, the button will be pill shaped.
	 * @type {boolean}
	 * @default false
	 * */
	pill: PropTypes.bool,
	/**
	 * If true, the button will be text shaped.
	 * @type {boolean}
	 * @default false
	 * */
	text: PropTypes.bool,
	/**
	 * The size of the button.
	 * @type {string}
	 * @default "medium"
	 * */
	size: PropTypes.oneOf(["small", "medium", "large"]),
	/**
	 * Callback fired when the button is clicked.
	 * @type {function}
	 * @default null
	 * */
	onClick: PropTypes.func,
	/**
	 * If true, the button will be loading.
	 * @type {boolean}
	 * @default false
	 * */
	loading: PropTypes.bool,
	/**
	 * If true, the button will be block and width 100%.
	 * @type {boolean}
	 * @default false
	 * */
	block: PropTypes.bool,
	/**
	 * The type of the button.
	 * @type {string}
	 * @default "button"
	 * */
	htmlType: PropTypes.oneOf(["button", "submit", "reset"]),
};

export default SimpleButton;
