import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import cx from 'classnames';
import "./SplitScreen.scss";
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { get } from 'lodash';
import { truncateStr } from '../../utils/text/turncate';
import { hsFor1280 } from '../../utils/scale';

const ArrowRight = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99769 12.5273C4.73409 12.2637 4.73409 11.8363 4.99769 11.5727L8.57039 7.99998L4.99769 4.42727C4.73409 4.16367 4.73409 3.73628 4.99769 3.47268C5.26129 3.20907 5.68868 3.20907 5.95228 3.47268L10.0023 7.52268C10.2659 7.78628 10.2659 8.21367 10.0023 8.47727L5.95228 12.5273C5.68868 12.7909 5.26129 12.7909 4.99769 12.5273Z" fill="#8C61CB" />
    </svg>
)

const ExternalLink = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 7.58333V11.0833C10.5 11.3928 10.3771 11.6895 10.1583 11.9083C9.9395 12.1271 9.64275 12.25 9.33333 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V4.66667C1.75 4.35725 1.87292 4.0605 2.09171 3.84171C2.3105 3.62292 2.60725 3.5 2.91667 3.5H6.41667M8.75 1.75H12.25M12.25 1.75V5.25M12.25 1.75L5.83333 8.16667" stroke="#8C61CB" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const LinkDropDown = ({ containerClassName, isVisible, ebookLink, ebookDropdownClose, index, linkClose }) => {
    const history = useHistory();
    const [activeLink, setActiveLink] = useState('')
    const handleHoverOnLink = (link) => {
        setActiveLink(link);
    }
    const handleEbookLinkChange = (ebook) => {
        ebookDropdownClose()
    }
    const marginTop = index * Number(hsFor1280(50.5).match(/(\d+)/)[0]);
    return (
        <>
            <motion.div
                className={cx("ebookLinkDropdown", containerClassName)}
                animate={{
                    opacity: isVisible ? 1 : 0,
                    y: isVisible ? 0 : 10,
                    pointerEvents: isVisible ? "all" : "none"
                }}
                initial={{
                    opacity: isVisible ? 1 : 0,
                    y: isVisible ? 0 : 10,
                    pointerEvents: isVisible ? "all" : "none"
                }}
                style={{ marginTop: `${marginTop}vw` }}
                onMouseLeave={() => linkClose()}
            >
                {ebookLink && ebookLink.map((ebook, index) =>
                    <p key={index} className={cx('profile')}
                        onMouseEnter={() => handleHoverOnLink(ebook)}
                        style={{ width: '100%' }}
                    >
                        {ebook !== activeLink ?
                            <Link key={index} className='inactive-ebook-link' onClick={() => handleEbookLinkChange(ebook)}>
                                {truncateStr(ebook.title, 14)}
                            </Link> :
                            <div className='active-ebook-link'>
                                <Link to={`/ebook/${ebook.id}`} key={index} className='active-current-ebook-link'>
                                    {truncateStr(ebook.title, 14)}
                                </Link>
                                <ExternalLink />
                            </div>
                        }
                    </p>
                )}
            </motion.div>
        </>
    )
}

const EbookDropdown = ({ isVisible, containerClassName, ebooks, ebookDropdownClose, ...props }) => {
    const [ebooksData, setEbooksData] = useState([])
    const [ebookLink, setEbookLink] = useState([])
    const [activeTab, setActiveTab] = useState('')
    const [isEbookDropdownVisible, setIsEbookDropdownVisible] = useState(false)
    const [index, setIndex] = useState(0)
    const [ebook, setEbook] = useState()
    const [isVisibleLink, setIsVisibleLink] = useState(false)
    useEffect(() => {
        setEbooksData(Object.keys(ebooks))
    }, [ebooks])
    const handleEbookLink = (ebook) => {
        setActiveTab(ebook)
        setEbookLink(ebooks[ebook])
        setIsEbookDropdownVisible(true)
    }
    const linkClose = () => {
        setIsVisibleLink(false)
    }
    return (
        <>
            <motion.div
                className={cx("ebookDropdown", containerClassName)}
                animate={{
                    opacity: isVisible ? 1 : 0,
                    y: isVisible ? 0 : 10,
                    pointerEvents: isVisible ? "all" : "none"
                }}
                transition={{
                    delay: isVisible ? 0 : 0.3
                }}
                initial={{
                    opacity: isVisible ? 1 : 0,
                    y: isVisible ? 0 : 10,
                    pointerEvents: isVisible ? "all" : "none"
                }}
            >
                {ebooksData && ebooksData.map((ebook, index) =>
                    <p key={index} className={cx('profile', ebook === activeTab ? 'activeTab' : '')}
                        onMouseEnter={() => {
                            handleEbookLink(ebook)
                            setIndex(index)
                            setEbook(ebook)
                            setIsVisibleLink(true)
                        }}
                        onMouseLeave={() => {
                            ebookDropdownClose()
                            setActiveTab('')
                        }
                        }
                        style={{ width: '100%' }}
                    >
                        {ebook !== activeTab ? <p className='grade-text'>{ebook}</p> :
                            <div className='ebook-link'>
                                <p>{truncateStr(ebook, 14)}</p>
                                <ArrowRight />
                            </div>
                        }
                    </p>
                )}
                <LinkDropDown isVisible={isVisibleLink} index={index} ebookLink={ebookLink} ebookDropdownClose={ebookDropdownClose} linkClose={linkClose} />
            </motion.div>
        </>
    )
}

export default EbookDropdown