import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from 'react';
import styles from './element.module.scss'
import Droppable from '../../../../components/QuestionTypes/FibBlock/Droppable'

const UNDERSCORE_PATTERN = '___'

export function createStyleObject(classNames) {
  var elementStyle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var stylesheet = arguments.length > 2 ? arguments[2] : undefined;
  return classNames.reduce(function (styleObject, className) {
    return _objectSpread({}, styleObject, stylesheet[className]);
  }, elementStyle);
}
export function createClassNameString(classNames) {
  return classNames.join(' ');
}
export function createChildren(stylesheet, useInlineStyles) {
  var childrenCount = 0;
  return function (children) {
    childrenCount += 1;
    return children.map(function (child, i) {
      return createElement({
        node: child,
        stylesheet: stylesheet,
        useInlineStyles: useInlineStyles,
        key: "code-segment-".concat(childrenCount, "-").concat(i)
      });
    });
  };
}
export default function createElement(_ref) {
  var node = _ref.node,
      stylesheet = _ref.stylesheet,
      _ref$style = _ref.style,
      style = _ref$style === void 0 ? {} : _ref$style,
      useInlineStyles = _ref.useInlineStyles,
      section = _ref.section,
      onDrop = _ref.onDrop,
      blockTexts = _ref.blockTexts,
      fibCorrectOptions = _ref.fibCorrectOptions,
      reviewMode = _ref.reviewMode,
      onTextInputChange = _ref.onTextInputChange,
      fibInputTextValues = _ref.fibInputTextValues,
      key = _ref.key,
      updateDropAreaCoordinates = _ref.updateDropAreaCoordinates,
      expandEmptyBlockIndex = _ref.expandEmptyBlockIndex,
      removeTextFromDroppable = _ref.removeTextFromDroppable,
      incrementalId = _ref.incrementalId;
  var properties = node.properties,
      type = node.type,
      TagName = node.tagName,
      value = node.value;
      if (type === 'text') {
        if (value.trim() !== UNDERSCORE_PATTERN) {
      return value;
    }
    else{
      if(section==='fibInput'){
        const id = incrementalId.id
        incrementalId.id = incrementalId.id + 1
        const additionalStyles = {}
        /** Additional Styles for HomeworkReview Flow */
        if (reviewMode) {
          additionalStyles.pointerEvents = 'none'
          additionalStyles.color = '#65DA7A'
        }
        if (fibCorrectOptions && fibCorrectOptions[id] && fibInputTextValues && fibInputTextValues[id] && reviewMode) {
          if (fibCorrectOptions[id].length) {
            if (fibCorrectOptions[id].includes(fibInputTextValues[id])) {
              additionalStyles.color = '#65DA7A'
              // additionalStyles.borderColor = '#65DA7A'
            } else {
              additionalStyles.color = '#FF5744'
              // additionalStyles.borderColor = '#FF5744'
            }
          }
        }
        return React.createElement('input', { key: incrementalId, id: id,
            onChange: e => onTextInputChange(id, e.target.value),
            className: styles.input,
            style: additionalStyles,
            autoComplete: 'off',
            spellCheck: 'false',
            value: fibInputTextValues && fibInputTextValues[id] ? fibInputTextValues[id] : ''
          })
      }
      else{
        if (incrementalId) {
          incrementalId.id = incrementalId.id+1
          const id = incrementalId.id
        return (
          React.createElement(Droppable, {
            id: id,
            updateDropAreaCoordinates: updateDropAreaCoordinates,
            onDrop: onDrop,
            blockTexts: blockTexts,
            fibCorrectOptions: fibCorrectOptions,
            reviewMode: reviewMode,
            removeTextFromDroppable: removeTextFromDroppable,
            expandEmptyBlockIndex: expandEmptyBlockIndex
          })
          )
        }
      }
    }
  } else if (TagName) {
    var childrenCreator = createChildren(stylesheet, useInlineStyles);
    var nonStylesheetClassNames = useInlineStyles && properties.className && properties.className.filter(function (className) {
      return !stylesheet[className];
    });
    var className = nonStylesheetClassNames && nonStylesheetClassNames.length ? nonStylesheetClassNames : undefined;
    var props = useInlineStyles ? _objectSpread({}, properties, {
      className: className && createClassNameString(className)
    }, {
      style: createStyleObject(properties.className, Object.assign({}, properties.style, style), stylesheet)
    }) : _objectSpread({}, properties, {
      className: createClassNameString(properties.className)
    });
    var children = childrenCreator(node.children);
    return React.createElement(TagName, _extends({
      key: key
    }, props), children);
  }
}
