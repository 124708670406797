import gql from 'graphql-tag'
import { get } from 'lodash'
import { getToasterBasedOnType } from '../components/Toaster'
import config from '../config'
import duck from '../duck'
import store from '../store'
import { isSubDomainActive } from '../utils/extractSubdomain'
import isSchoolWebsite from '../utils/isSchoolWebsite'
import getUserForState, { SIGNUP_RESPONSE_SCHEMA } from './utils/signupLoginUser'
export const failureToasterProps = e => {
  if (e && e.includes('Daily OTP limit exceeded, please contact support.')) {
    if (window.fcWidget) {
      window.fcWidget.open({ name: "Inbox" })
    }
  }
  if (e && e.includes('One or more records sent in Connect are not present in db')) {
    return {
      type: 'info',
      message: 'Please select a child to register',
      autoClose: 4000
    }
  }
  if (e && e.includes('Child is already registered for event')) {
    return {
      type: 'info',
      message: e,
      autoClose: 4000
    }
  }
  return {
    type: 'error',
    message: e,
    autoClose: 4000
  }
}
const batchSlots = `
batch {
  id
  b2b2ctimeTable {
    ${new Array(24).fill('').map((_, i) => `slot${i}`).join('\n')}
  }
}
`

const loginViaPassword = (input, shouldLogin, key, callbackIfMultipleChildren = () => { }, teacherApp) =>
  duck.createQuery({
    query: gql`
    mutation{
      loginViaPassword(input:{
        ${input.email ? `email: "${input.email.trim()}"` : ''}
        ${input.username ? `username: "${input.username.trim()}"` : ''}
        password: "${input.password}"
      }){
        ${SIGNUP_RESPONSE_SCHEMA()}
      }
    }
    `,

    variables: {
      tokenType: 'appTokenOnly'
    },
    changeExtractedData: (_, originalData) => {
      if (originalData && originalData.loginViaPassword && originalData.loginViaPassword) {
        localStorage.setItem('appVersion', process.env.REACT_APP_VERSION)
        const timezone = get(originalData, 'loginViaPassword.timezone')
        if (timezone && timezone !== 'null') {
          localStorage.setItem('timezone', get(originalData, 'loginViaPassword.timezone'))
        }
        if (teacherApp) {
          if (
            get(originalData, "loginViaPassword.role") === config.MENTOR &&
            get(originalData, "loginViaPassword.secondaryRole") === config.SCHOOL_TEACHER
          ) {
            if (get(originalData, 'loginViaPassword.mentorProfile.schools') && get(originalData, 'loginViaPassword.mentorProfile.schools').length === 0) {
              return getToasterBasedOnType(failureToasterProps('Not Authorized'))
            }
            const academicYears = get(originalData, 'loginViaPassword.academicYears', [])
            let academicYear
            const currentYear = new Date()
            if (academicYears && academicYears.length > 1) {
              academicYears.length && academicYears.forEach(data => {
                if (new Date(get(data, 'startDate')) <= currentYear && new Date(get(data, 'endDate')) >= currentYear) {
                  localStorage.setItem('academicYear', get(data, 'id'))
                  academicYear = get(data, 'id')
                }
              })
              if (!academicYear && academicYears && academicYears.length) {
                academicYears.length && academicYears.forEach(data => {
                  if (new Date(get(data, 'startDate')).getFullYear() === currentYear.getFullYear()) {
                    localStorage.setItem('academicYear', get(data, 'id'))
                  }
                })
              }
            }
            return {
              user: {
                ...originalData.loginViaPassword,
                schoolTeacher: originalData.loginViaPassword,
                rawData: originalData.loginViaPassword
              },
            };
          }
          if (get(originalData, "loginViaPassword.role") === config.MENTOR) {
            const isSchoolTrainer = get(originalData, "loginViaPassword.roles", []).includes(config.SCHOOL_TRAINER) 
            if (isSchoolTrainer) {
              return {
                user: {
                  ...originalData.loginViaPassword,
                  schoolTeacher: originalData.loginViaPassword,
                  rawData: originalData.loginViaPassword
                },
              };
            }
          }
          return getToasterBasedOnType(failureToasterProps('Oops! You need to use the Student app to login.'))
        }
        if (originalData.loginViaPassword.role === 'selfLearner' || ((originalData.loginViaPassword.role === 'mentor') && !get(originalData, 'loginViaPassword.children[0]'))) {
          if (!shouldLogin && originalData.loginViaPassword.role !== 'mentor') {
            store.dispatch({
              type: 'user/fetch/failure',
              error: {
                status: 'UnexpectedError',
                errors: [{
                  message: "Thank you for logging in, we'll get back to you shortly."
                }],
                data: {
                  loginViaPassword: null
                }
              },
              autoReducer: true,
              key: 'loggedinUser',
              uniqId: null
            })
            return {}
          } else {
            return {
              user: {
                ...originalData.loginViaPassword
              }
            }
          }
        }
        const { ...parent } = originalData.loginViaPassword
        if (get(originalData, 'loginViaPassword.role') === 'mentor') {
          return {
            user: {
              ...get(originalData, 'loginViaPassword.children[0]'),
              isMentorLoggedIn: true,
              parent: { ...parent, parentProfile: { children: [get(originalData, 'loginViaPassword.mentorProfile.studentProfile.user')] } },
              email: originalData.loginViaPassword.email,
              createdAt: originalData.loginViaPassword.createdAt,
              rawData: originalData.loginViaPassword
            }
          }
        }
        if (isSchoolWebsite() || isSubDomainActive) {
          if (get(originalData, 'loginViaPassword.role') === config.SCHOOL_ADMIN) {
            return {
              user: {
                ...originalData.loginViaPassword,
                schoolAdmin: originalData.loginViaPassword
              }
            }
          }
        }
        // before user loggingIn reset the redux store to default values
        // store.dispatch({
        //   type: 'LOGOUT'
        // })
        return getUserForState(originalData.loginViaPassword, callbackIfMultipleChildren);
      }
    },
    type: 'user/fetch',
    key: key || 'loggedinUser'
  })


export default loginViaPassword
